// extracted by mini-css-extract-plugin
export var softBlueGradient = "solutionsPage-module--soft-blue-gradient--Np-bR";
export var containerLarge = "solutionsPage-module--containerLarge--12DrI";
export var solutions = "solutionsPage-module--solutions--t8vFC";
export var stepsItem = "solutionsPage-module--stepsItem--3Pdhk";
export var stepCircle = "solutionsPage-module--stepCircle--1KYje";
export var innerTextLink = "solutionsPage-module--innerTextLink--VpK1M";
export var aboutTraining = "solutionsPage-module--aboutTraining--13att";
export var trainingDates = "solutionsPage-module--trainingDates--2x7wB";
export var image = "solutionsPage-module--image--udy0O";
export var items = "solutionsPage-module--items--Vq7fA";
export var links = "solutionsPage-module--links--3XbWq";
export var item = "solutionsPage-module--item--1ikLS";
export var cta = "solutionsPage-module--cta--bfy4F";
export var cardAbout = "solutionsPage-module--cardAbout--kLxv1";
export var header = "solutionsPage-module--header--1AvyP";
export var content = "solutionsPage-module--content--1A47c";
export var cardIcon = "solutionsPage-module--cardIcon--kIufb";
export var titleContent = "solutionsPage-module--titleContent--2xzhl";
export var trainingContent = "solutionsPage-module--trainingContent--3VTZT";
export var trainingGrid = "solutionsPage-module--trainingGrid--2EJII";
export var trainingCard = "solutionsPage-module--trainingCard--rgQs-";
export var testimonialsContent = "solutionsPage-module--testimonialsContent--1h29q";
export var sliderBox = "solutionsPage-module--sliderBox--SWzDy";
export var slider = "solutionsPage-module--slider--piRNS";
export var arrows = "solutionsPage-module--arrows--2_Ocj";
export var disabled = "solutionsPage-module--disabled--2LKLc";
export var count = "solutionsPage-module--count--3zRfB";
export var slick = "solutionsPage-module--slick--vLhU4";
export var itemBox = "solutionsPage-module--itemBox--Bsme_";
export var photo = "solutionsPage-module--photo--3pylt";
export var circle = "solutionsPage-module--circle--3ba-X";
export var description = "solutionsPage-module--description--1ln0w";
export var quoteIcon = "solutionsPage-module--quoteIcon--2s2P8";
export var boxMethodology = "solutionsPage-module--boxMethodology--2pyOo";
export var methodology = "solutionsPage-module--methodology--1ijL1";