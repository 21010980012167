import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/okr/okr1.jpeg'
import banner2 from '../../../images/pages/okr/okr2.jpeg'
import {
  faChartBar,
  faFlagCheckered,
  faBalanceScale,
  faRocket,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>OKR, OBJETIVOS E RESULTADOS CHAVES QUE CONDUZEM AO SUCESSO</>,
    description: (
      <>
        Transforme sua estratégia em resultados, simplifique o planejamento e
        execução de tarefas, impondo uma disciplina contínua
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>OKR, METODOLOGIA DE GESTÃO ÁGIL COM FOCO EM RESULTADO</>,
    description: (
      <>
        Implemente a metodologia de gestão criada pela Intel e adotada por
        diversas empresas do Vale do Silício
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const Okr: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faChartBar,
      title: `Foco e produtividade`,
      description: (
        <>
          A metodologia OKR proporcionará mais engajamento aos colaboradores,
          pois objetivos bem definidos trazem motivação e propósito para as
          equipes. Além disso, haverá um melhor alinhamento entre as equipes e a
          empresa, fazendo com que todos caminhem para a mesma direção.
        </>
      ),
    },
    {
      faIcon: faFlagCheckered,
      title: `Metodologia ágil`,
      description: (
        <>
          Diferentemente das metodologias de gestão tradicionais, com
          planejamentos anuais estáticos, o OKR usa uma abordagem ágil através
          da execução de ciclos de metas curtos, para que a empresa possa se
          adaptar e responder com mais velocidade às mudanças.
        </>
      ),
    },
    {
      faIcon: faBalanceScale,
      title: `Transparência e democratização`,
      description: (
        <>
          O propósito do OKR é criar um alinhamento na organização. Para isso,
          os OKRs são públicos para todos os níveis da empresa - do estratégico
          ao operacional. É boa prática utilizar a gestão à vista para
          divulgação a todos os colaboradores, seja por meio de dashboards
          impressos ou online, na intranet.
        </>
      ),
    },
    {
      faIcon: faRocket,
      title: `Metas ambiciosas`,
      description: (
        <>
          A filosofia por trás da metodologia OKR requer metas ambiciosas,
          afinal de contas, se a empresa está alcançando sempre 100% das metas,
          é bem provável de que estas sejam muito fáceis. Metas desafiadoras
          fazem os times repensarem sua maneira de trabalhar, para maximizar o
          desempenho.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Treinamento',
      description: (
        <>
          A primeira etapa da implantação da metodologia consiste no treinamento
          e engajamento de todos os colaboradores, trabalhando os principais
          conceitos e fornecendo templates para a criação de objetivos e
          resultados chaves nos níveis estratégico, tático e operacional.
        </>
      ),
    },
    {
      count: 2,
      title: 'Criação e ação',
      description: (
        <>
          Na segunda etapa serão definidos os objetivos e respectivos resultados
          chaves. Os objetivos devem ser simples, curtos e fáceis de memorizar.
          Já os resultados chaves são métricas, e não um descritivo de tarefas a
          serem realizadas.
        </>
      ),
    },
    {
      count: 3,
      title: 'Monitoramento e análise de resultados',
      description: (
        <>
          OKRs são periodicamente definidos, medidos e reavaliados - geralmente
          trimestralmente. É importante fazer o monitoramento em ciclos curtos,
          porque vivemos em uma sociedade volátil, os objetivos mudam
          organicamente e se o ciclo for muito extenso, há o risco de que os
          objetivos não façam mais sentido para a organização.
        </>
      ),
    },
    {
      count: 4,
      title: 'Identificar melhorias',
      description: (
        <>
          Nesta etapa é o momento de identificar melhorias e aplicá-las, fazendo
          ajustes, definindo padrões, priorizando objetivos, permeando a
          metodologia implantada na cultura da organização.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="OKR - Objetivos e Resultados Chaves - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Propor soluções através do uso da metodologia de gestão ágil OKR,
              simplificando o planejamento e execução de tarefas para alavancar
              resultados."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            implementar a gestão OKR em sua organização.`}
          />
        </div>
      </section>

      {/* <SuccessCases listCases={cases} /> */}
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default Okr
