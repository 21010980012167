import React from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import * as styles from '../../styles/pages/solutionsPage.module.scss'
import { Button } from '../Button'

interface MethodologySectionProps {
  items: itemList[]
}

interface itemList {
  faIcon: IconProp
  title: string
  description: React.ReactNode
  externalLink?: {
    url: string
    text: string
    sameOrigin?: boolean
  }
}

export const FeaturesSection: React.FC<MethodologySectionProps> = ({
  items,
}) => {
  return (
    <>
      <div className={clsx([`container`, styles.containerLarge])}>
        <div className="row mt-3">
          {items &&
            items.map((item, key) => (
              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
                key={key}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={item.faIcon} />
                </div>
                <b>{item.title}</b>
                <p>{item.description}</p>

                {item.externalLink && (
                  <>
                    {item.externalLink.sameOrigin ? (
                      <AnchorLink to={item.externalLink.url}>
                        {item.externalLink.text}
                      </AnchorLink>
                    ) : (
                      <a
                        href={item.externalLink.url}
                        target="_blank"
                        rel="noopener"
                      >
                        {item.externalLink.text}
                      </a>
                    )}
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="container">
        <div className="row mt-0 mt-sm-5 align-items-center justify-content-center">
          <AnchorLink to={'/#solucoes'}>
            <Button>Conheça todos os nossos serviços</Button>
          </AnchorLink>
        </div>
      </div>
    </>
  )
}
