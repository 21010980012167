import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from '../../styles/pages/solutionsPage.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons'

import avatar1 from '../../images/pages/bpm/BrunaBPM.jpeg'
import avatar2 from '../../images/pages/bpm/brasifBPM.png'
import avatar3 from '../../images/pages/bpm/DouglasBPM.jpeg'

const clients = [
  {
    photo: avatar1,
    name: 'Bruna Andrade Coghi',
    role: 'Coordenadora de processos na Fundação Padre Albino',
    text: `Meu nome é Bruna Andrade Coghi, atualmente sou coordenadora de melhoria de processos dos hospitais da fundação Padre Albino. E há 2 anos eu fiz o curso de mapeamento de processos na Valore, no qual abriu minha cabeça, me ajudou padronizar a forma com que eu mapeava, entendia e contribuiu com as boas práticas e melhorias. Se hoje eu estou onde estou, a muito devo à Valore.`,
  },
  {
    photo: avatar2,
    name: 'Brasif Serviços, peças e Locação de Máquinas ',
    role: 'Jundiaí, SP',
    text: 'O time de processos da Brasif foi capacitado através do treinamento para formação de Analistas de Processos BPM e com a mentoria para criação do escritório de processos. Com o progresso adquirido, foi capaz de executar a metodologia de forma completa no desenvolvimento de melhorias de processos, desde o mapeamento AS IS, passando pela construção das melhorias através de técnicas como "brownpaper".',
  },
  {
    photo: avatar3,
    name: 'Douglas Anderson Correia',
    role: 'Analista de Processos Autônomo',
    text: 'Após a realização do curso de Analista de Processos, meus horizontes se ampliaram!!! Hoje trabalho como prestador de serviço e tenho feito Análises de Processos em pequenas empresas através da minha própria abertura de empresa. Os resultados tem surgido devagar, porém constantes. Só tenho a agradecer a Valore Brasil pela possibilidade de realizar esse curso e de me ajudar a dar um passo tão grande na minha Carreira!!!',
  },
]

export const Testimonials: React.FC = () => {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState({
    slideIndex: 1,
  })

  return (
    <div className={clsx([styles.sliderBox])}>
      <div className={clsx([styles.slider, 'col-12 col-md-12'])}>
        <div className={styles.arrows}>
          <span
            onClick={() => {
              sliderRef.current?.slickPrev()
            }}
            className={currentSlide.slideIndex === 1 ? styles.disabled : ''}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className={styles.count}>
            {currentSlide.slideIndex}/{clients.length}
          </div>
          <span
            onClick={() => {
              sliderRef.current?.slickNext()
            }}
            className={
              currentSlide.slideIndex === clients.length ? styles.disabled : ''
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>
        <Slider
          ref={sliderRef}
          className={styles.slick}
          adaptiveHeight={true}
          infinite={false}
          autoplay={false}
          slidesToShow={1}
          slidesToScroll={1}
          dots={false}
          draggable={false}
          speed={300}
          arrows={false}
          beforeChange={(current, next) =>
            setCurrentSlide((prevState) => ({
              ...prevState,
              slideIndex: next + 1,
            }))
          }
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
                arrows: false,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
              },
            },
          ]}
        >
          {clients.map((client, index) => (
            <div className={styles.itemBox} key={index}>
              <div className={styles.item}>
                <div className={styles.photo}>
                  <div className={styles.circle}>
                    <img src={client.photo} alt={client.name} />
                  </div>
                </div>
                <div className={styles.description}>
                  <p>
                    <i>" </i>
                    {client.text}
                    <i>"</i>
                  </p>
                  <strong>{client.name}</strong>
                  <small>{client.role}</small>
                  <FontAwesomeIcon
                    className={styles.quoteIcon}
                    icon={faQuoteRight}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
