import React from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from '../../styles/pages/solutionsPage.module.scss'
import { Button } from '../Button'

interface MethodologySectionProps {
  items: itemList[]
  locationPath: string
  descripitionSection?: string
}

interface itemList {
  count: number
  title: string
  description: React.ReactNode
}

export const MethodologySection: React.FC<MethodologySectionProps> = ({
  locationPath,
  items,
  descripitionSection,
}) => {
  return (
    <div className={clsx([`container`, styles.containerLarge])}>
      <div className="row align-items-center">
        <div className="col-lg-4 mb-3 mb-lg-0 pl-lg-5 pr-4">
          <h2 className="mb-3" style={{ color: '#1fa9e2' }}>
            Metodologia Utilizada
          </h2>
          <p className="mb-3">
            {descripitionSection ? (
              descripitionSection
            ) : (
              <>
                Conheça agora os 04 principais passos de nossa metologia, para
                implementar a mudança na sua organização.
              </>
            )}
          </p>
          <AnchorLink to={`${locationPath}#contato`} className="hidden-mobile">
            <Button>Dúvidas? Fale com a gente</Button>
          </AnchorLink>
        </div>
        <div className={clsx(['col-lg-8 pl-4', styles.boxMethodology])}>
          <ul className={styles.methodology}>
            {items &&
              items.map((item: itemList, key) => (
                <li key={key}>
                  <span>
                    <small>#</small>
                    {item.count}
                  </span>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
            <li className="pt-4 justify-content-center hidden-mobile-up">
              <AnchorLink to={`${locationPath}#contato`}>
                <Button>Dúvidas? Fale com a gente</Button>
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
